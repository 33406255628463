import Line from '@labtest/presentation/atoms/Line';
import ContentLoader from 'react-content-loader';

export const BannerSkeleton = () => {
	return (
		<div
			style={{
				width: '100%'
			}}>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={'100%'} // Adjusted for full layout
				viewBox='0 0 1320 420' // Adjusted for full layout
				backgroundColor='#f3f3f3'
				foregroundColor='#ecebeb'>
				<rect x='0' y='0' rx='10' ry='10' width='100%' height='420' />
			</ContentLoader>
		</div>
	);
};

export const TestSkeletonOne = (props) => (
	<div
		className='w-full border-1-grey200 rounded-6'
		style={{
			height: '100%',
			maxHeight: '214px'
		}}>
		<div className='p-10'>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={112}
				// viewBox='0 0 950 132'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				{/* Image */}
				<rect x='0' y='0' rx='10' ry='10' width='114' height='114' />

				<rect x='124' y='5' rx='2' ry='2' width='240' height='20' />
				<rect x='124' y='35' rx='2' ry='2' width='230' height='15' />
				<rect x='124' y='60' rx='2' ry='2' width='133' height='15' />
				<rect x='124' y='85' rx='2' ry='2' width='240' height='20' />
			</ContentLoader>
		</div>
		<Line />
		<div className='flex justify-between p-10'>
			<div className='w-full'>
				<ContentLoader
					speed={2}
					width={'100%'}
					height={57}
					// viewBox='0 0 950 132'
					backgroundColor='#e8e8e8'
					foregroundColor='#fcfcfc'
					{...props}>
					<rect x='0' y='0' rx='2' ry='2' width='240' height='22' />
					<rect x='0' y='35' rx='2' ry='2' width='240' height='22' />
				</ContentLoader>
			</div>
			<div className='w-full flex justify-end'>
				<ContentLoader
					speed={2}
					width={'150'}
					height={44}
					// viewBox='0 0 150 44'
					backgroundColor='#e8e8e8'
					foregroundColor='#fcfcfc'
					{...props}>
					{/* Button */}
					<rect x='0' y='0' rx='6' ry='6' width='150' height='44' />
				</ContentLoader>
			</div>
		</div>
	</div>
);

export const TestSkeletonTwo = (props) => (
	<div className='w-full border-1-grey200 rounded-6'>
		<div className='w-full d-flex p-10'>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={112}
				// viewBox='0 0 950 132'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				{/* Image */}
				<rect x='0' y='0' rx='10' ry='10' width='114' height='114' />

				<rect x='124' y='5' rx='2' ry='2' width='240' height='20' />
				<rect x='124' y='35' rx='2' ry='2' width='230' height='15' />
				<rect x='124' y='60' rx='2' ry='2' width='133' height='15' />
				<rect x='124' y='85' rx='2' ry='2' width='240' height='20' />
			</ContentLoader>
			<div className='w-full d-flex items-flex-end justify-end'>
				<ContentLoader
					speed={2}
					width={'150'}
					height={44}
					// viewBox='0 0 150 44'
					backgroundColor='#e8e8e8'
					foregroundColor='#fcfcfc'
					{...props}>
					{/* Button */}
					<rect x='0' y='0' rx='6' ry='6' width='150' height='44' />
				</ContentLoader>
			</div>
		</div>
		<Line />
		<div className='w-full flex justify-between p-10'>
			<ContentLoader
				speed={2}
				width={240}
				height={22}
				// viewBox='0 0 950 132'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				<rect x='0' y='0' rx='2' ry='2' width='240' height='22' />
			</ContentLoader>

			<ContentLoader
				speed={2}
				width={240}
				height={22}
				// viewBox='0 0 950 132'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				<rect x='0' y='0' rx='2' ry='2' width='240' height='22' />
			</ContentLoader>
		</div>
	</div>
);

export const CartTestSkeleton = (props) => (
	<div
		className='d-flex items-flex-end justify-between gap-20 p-10'
		style={{
			width: '100%'
		}}>
		<ContentLoader
			speed={2}
			width={'100%'}
			height={85}
			// viewBox='0 0 300 85'
			backgroundColor='#e8e8e8'
			foregroundColor='#fcfcfc'
			{...props}>
			{/* Image */}
			<rect x='0' y='0' rx='10' ry='10' width='85' height='85' />

			<rect x='95' y='10' rx='2' ry='2' width='140' height='10' />
			<rect x='95' y='30' rx='2' ry='2' width='130' height='10' />
			<rect x='95' y='50' rx='2' ry='2' width='80' height='10' />
			<rect x='95' y='70' rx='2' ry='2' width='120' height='10' />
		</ContentLoader>
		<div className=''>
			<ContentLoader
				speed={2}
				width={'100'}
				height={28}
				// viewBox='0 0 100 28'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				{/* Button */}
				<rect x='1' y='1' rx='10' ry='10' width='100' height='28' />
			</ContentLoader>
		</div>
	</div>
);

export const DateTimeScheduleSkeleton = (props) => {
	return (
		<ContentLoader
			speed={2}
			width={'130px'}
			height={'35px'}
			// viewBox='0 0 800 200'
			backgroundColor='#e8e8e8'
			foregroundColor='#fcfcfc'
			{...props}>
			<rect x='0' y='0' rx='3' ry='3' width='130' height='35' />
			{/* <rect x='1' y='46' rx='0' ry='0' width='468' height='10' /> */}
		</ContentLoader>
	);
};

export const TestFilterCriteriaSkeleton = (props) => (
	<ContentLoader
		speed={2}
		width={'100%'}
		height={'170'}
		// viewBox='0 0 220 165'
		backgroundColor='#e8e8e8'
		foregroundColor='#fcfcfc'
		{...props}>
		<rect x='1' y='1' rx='2' ry='2' width='200' height='20' />

		<circle cx='13' cy='45' r='10' />
		<rect x='35' y='38' rx='2' ry='2' width='150' height='15' />

		<circle cx='13' cy='68' r='10' />
		<rect x='35' y='61' rx='0' ry='0' width='150' height='15' />

		<circle cx='13' cy='90' r='10' />
		<rect x='35' y='83' rx='0' ry='0' width='150' height='15' />

		<circle cx='13' cy='112' r='10' />
		<rect x='35' y='105' rx='0' ry='0' width='150' height='15' />

		<circle cx='13' cy='134' r='10' />
		<rect x='36' y='126' rx='0' ry='0' width='150' height='15' />

		<circle cx='13' cy='156' r='10' />
		<rect x='36' y='146' rx='0' ry='0' width='150' height='15' />
	</ContentLoader>
);

export const TopContentSkeleton = (props) => (
	<ContentLoader
		speed={2}
		width={'100%'}
		height={'145'}
		// viewBox='0 0 240 145'
		backgroundColor='#e8e8e8'
		foregroundColor='#fcfcfc'
		{...props}>
		<rect x='1' y='1' rx='10' ry='10' width='100%' height='145' />
	</ContentLoader>
);

export const CallToActionSkeleton = (props) => (
	<ContentLoader
		speed={2}
		width={'100%'}
		height={'100'}
		// viewBox='0 0 410 100'
		backgroundColor='#e8e8e8'
		foregroundColor='#fcfcfc'
		{...props}>
		<rect x='1' y='1' rx='10' ry='10' width='100%' height='100' />
	</ContentLoader>
);

export const LabPartnersSkeleton = (props) => (
	<div
		className='w-full border-1-grey200 rounded-6'
		style={{
			height: '100%',
			maxHeight: '122px'
		}}>
		<div className='p-10'>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={112}
				// viewBox='0 0 950 132'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				{/* Image */}
				<rect x='0' y='0' rx='10' ry='10' width='100' height='100' />

				<rect x='110' y='8' rx='2' ry='2' width='240' height='20' />
				<rect x='110' y='40' rx='2' ry='2' width='200' height='20' />
				<rect x='110' y='72' rx='2' ry='2' width='133' height='20' />
			</ContentLoader>
		</div>
	</div>
);

export const ImageMagnifierSkeleton = () => {
	return (
		<div
			className={`px-25 border-1-grey300 rounded-10`}
			style={{
				backgroundColor: '#fff',
				display: 'flex',
				gap: '10px'
			}}>
			<div
				style={{
					minWidth: '15%'
				}}>
				<svg
					role='img'
					width='100%'
					height='600'
					aria-labelledby='loading-aria'
					// viewBox="0 0 100 600"
					preserveAspectRatio='none'>
					<title id='loading-aria'>Loading...</title>
					<rect
						x='0'
						y='0'
						width='100%'
						height='100%'
						clipPath='url(#clipPath)'
						style={{ fill: 'url("#fill")' }}></rect>
					<defs>
						<clipPath id='clipPath'>
							{/* Adjust both x and y attribute values to move the smaller quadrilaterals */}
							<rect x='10' y='20' rx='0' ry='0' width='70' height='70' />
							<rect x='10' y='94' rx='0' ry='0' width='70' height='70' />
							<rect x='10' y='168' rx='0' ry='0' width='70' height='70' />
							<rect x='10' y='242' rx='0' ry='0' width='70' height='70' />
							<rect x='10' y='316' rx='0' ry='0' width='70' height='70' />
							<rect x='10' y='390' rx='0' ry='0' width='70' height='70' />
							<rect x='10' y='464' rx='0' ry='0' width='70' height='70' />
						</clipPath>
						<linearGradient id='fill'>
							<stop offset='0.599964' stopColor='#f3f3f3' stopOpacity='1'>
								<animate
									attributeName='offset'
									values='-2; -2; 1'
									keyTimes='0; 0.25; 1'
									dur='2s'
									repeatCount='indefinite'></animate>
							</stop>
							<stop offset='1.59996' stopColor='#ecebeb' stopOpacity='1'>
								<animate
									attributeName='offset'
									values='-1; -1; 2'
									keyTimes='0; 0.25; 1'
									dur='2s'
									repeatCount='indefinite'></animate>
							</stop>
							<stop offset='2.59996' stopColor='#f3f3f3' stopOpacity='1'>
								<animate
									attributeName='offset'
									values='0; 0; 3'
									keyTimes='0; 0.25; 1'
									dur='2s'
									repeatCount='indefinite'></animate>
							</stop>
						</linearGradient>
					</defs>
				</svg>
			</div>
			<div
				className='big-shape-container'
				style={{
					minWidth: '85%',
					maxHeight: '500px'
				}}>
				{/* Big Quadrilateral */}
				<div className='big-shape' />
				<svg
					role='img'
					width='580'
					height='500'
					aria-labelledby='loading-aria'
					viewBox='0 0 580 500'
					preserveAspectRatio='none'>
					<title id='loading-aria'>Loading...</title>
					<rect
						x='0'
						y='0'
						width='100%'
						height='100%'
						clipPath='url(#clipPath_1)'
						style={{ fill: 'url("#fill")' }}></rect>
					<defs>
						<clipPath id='clipPath_1'>
							<rect x='10' y='20' rx='0' ry='0' width='580' height='500' />
						</clipPath>
						<linearGradient id='fill'>
							<stop offset='0.599964' stopColor='#f3f3f3' stopOpacity='1'>
								<animate
									attributeName='offset'
									values='-2; -2; 1'
									keyTimes='0; 0.25; 1'
									dur='2s'
									repeatCount='indefinite'></animate>
							</stop>
							<stop offset='1.59996' stopColor='#ecebeb' stopOpacity='1'>
								<animate
									attributeName='offset'
									values='-1; -1; 2'
									keyTimes='0; 0.25; 1'
									dur='2s'
									repeatCount='indefinite'></animate>
							</stop>
							<stop offset='2.59996' stopColor='#f3f3f3' stopOpacity='1'>
								<animate
									attributeName='offset'
									values='0; 0; 3'
									keyTimes='0; 0.25; 1'
									dur='2s'
									repeatCount='indefinite'></animate>
							</stop>
						</linearGradient>
					</defs>
				</svg>
			</div>
		</div>
	);
};

export const TestCategorySkeletonOne = (props) => (
	<ContentLoader
		speed={2}
		width={'100%'}
		height={'300'}
		// viewBox='0 0 300 320'
		backgroundColor='#e8e8e8'
		foregroundColor='#fafafa'
		{...props}>
		<rect x='1' y='1' rx='10' ry='10' width='100%' height='200' />
		<rect x='20' y='220' rx='6' ry='6' width='60%' height='30' />
		<rect x='20' y='270' rx='6' ry='6' width='50%' height='30' />
	</ContentLoader>
);

export const TestCategorySkeletonTwo = (props) => (
	<div
		className='flex flex-column gap-20 border1-grey100 bg-white'
		style={{
			height: '192px'
		}}>
		<div className=''>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={'100%'}
				viewBox='0 0 200 140' // Adjust the viewBox to match the aspect ratio
				backgroundColor='#e8e8e8'
				foregroundColor='#fafafa'
				{...props}>
				<circle cx='100' cy='80' r='55' />
			</ContentLoader>
		</div>
		<div className='px-30'>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={'25'}
				// viewBox="0 0 476 124"
				backgroundColor='#e8e8e8'
				foregroundColor='#fafafa'
				{...props}>
				<rect x='1' y='1' rx='2' ry='2' width='100%' height='25' />
			</ContentLoader>
		</div>
	</div>
);

export const TestCategoryPageSkeletonOne = (props) => (
	<ContentLoader
		speed={2}
		width={'100%'}
		height={205}
		// viewBox='0 0 100 205'
		backgroundColor='#e8e8e8'
		foregroundColor='#fcfcfc'
		{...props}>
		<rect x='1' y='1' rx='10' ry='10' width='100%' height='150' />
		<rect x='1' y='180' rx='6' ry='6' width='100%' height='25' />
	</ContentLoader>
);

export const TestCategoryPageSkeletonTwo = (props) => (
	<div className='flex justify-between gap-20 border-1-grey200 rounded-10 p-20'>
		<div className=''>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={30}
				// viewBox='0 0 100 30'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				<rect x='1' y='1' rx='4' ry='4' width='350' height='30' />
			</ContentLoader>
		</div>
		<div className='flex gap-10'>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={30}
				// viewBox='0 0 100 30'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				<rect x='1' y='1' rx='4' ry='4' width='300' height='30' />
			</ContentLoader>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={30}
				// viewBox='0 0 100 30'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				<rect x='1' y='1' rx='4' ry='4' width='300' height='30' />
			</ContentLoader>
		</div>
	</div>
);

export const TestCategoryPageFilterSkeleton = (props) => (
	<div className='flex justify-between gap-20 border-1-grey200 rounded-10 p-20'>
		<div className=''>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={30}
				// viewBox='0 0 100 30'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				<rect x='1' y='1' rx='4' ry='4' width='350' height='30' />
			</ContentLoader>
		</div>
		<div className='flex gap-10'>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={30}
				// viewBox='0 0 100 30'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				<rect x='1' y='1' rx='4' ry='4' width='300' height='30' />
			</ContentLoader>
			<ContentLoader
				speed={2}
				width={'100%'}
				height={30}
				// viewBox='0 0 100 30'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				<rect x='1' y='1' rx='4' ry='4' width='300' height='30' />
			</ContentLoader>
		</div>
	</div>
);

export const SectionHeaderSkeleton = (props) => (
	<div className='d-flex justify-space-between gap-20'>
		<ContentLoader
			speed={2}
			width={'450'}
			height={45}
			viewBox='0 0 450 45'
			backgroundColor='#e8e8e8'
			foregroundColor='#fcfcfc'
			{...props}>
			<rect x='1' y='12' rx='4' ry='4' width='445' height='30' />
		</ContentLoader>
		<ContentLoader
			speed={2}
			width={'250'}
			height={45}
			viewBox='0 0 250 45'
			backgroundColor='#e8e8e8'
			foregroundColor='#fcfcfc'
			{...props}>
			<rect x='1' y='12' rx='4' ry='4' width='245' height='30' />
		</ContentLoader>
	</div>
);

export const TitleHeaderSkeleton = (props) => (
	<div className='w-full'>
		<ContentLoader
			speed={2}
			width={'100%'}
			height={30}
			// viewBox='0 0 1280 30'
			backgroundColor='#e8e8e8'
			foregroundColor='#fcfcfc'
			{...props}>
			<rect x='0' y='0' rx='4' ry='4' width='100%' height='30' />
		</ContentLoader>
	</div>
);
