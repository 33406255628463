import styles from './Line.module.scss';

interface I_Props {
	dashed?: string;
	height?: string;
	color?: 'bgltSuccessDark' | 'bgltGreyscale400';
}
const Line = ({ dashed, height, color }: I_Props) => {
	return <div className={`${styles.line} ${dashed ? styles.dashed : ''} ${height} ${styles[color]}`} />;
};

export default Line;
